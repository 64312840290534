import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  REACT_APP_NATIVE_APP_BASE_URL, REACT_APP_NATIVE_APP_DISCOVERY_URI,
} from "@brinks/common/utils/Config";
import axios from "axios";

export interface AuthSliceState {
  loading: boolean;
  isError: boolean;
  tokenData: any;
  OtpData: any;
  verifyOTPData: any;
  accessTokenData: any;
  isAuthenticated: boolean;
  viewLanguageFilter: boolean;

}

const initialState: AuthSliceState = {
  loading: false,
  isError: false,
  tokenData: null,
  accessTokenData: null,
  OtpData: null,
  verifyOTPData: null,
  isAuthenticated: false,
  viewLanguageFilter: false,

};

export const exchangeToken = createAsyncThunk<any, any>(
  "exchangeToken",
  async (data, { rejectWithValue }) => {
    console.log('exchangeToken', data)

    try {
      const res = await axios({
        method: "post",
        url: `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn`,
        data,
      });
      const loginResp = res.data;

      console.log('exchangeToken loginResp', loginResp)

      if (!loginResp.errorId) {
        await sendOtp(loginResp);
      }
      return loginResp;
    } catch (error: any) {
      if (!error?.response) {
        throw error
      }

      return rejectWithValue(error.response.data)
    }

  }
);

export const sendOtp = async (loginResp: any) => {
  const resp = await axios({
    method: "post",
    url: `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/factors/${loginResp._embedded.factors[0].id}/verify`,
    data: {
      stateToken: loginResp?.stateToken,
    },
  });
  return resp;
};

export const resetVerifyOTP = async (payload: any,passCode:any) => {
  const resp = await axios({
    method: "post",
    url: `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/factors/${payload._embedded.factors[0].id}/verify`,
    data: {
      stateToken: payload?.stateToken,
      passCode: passCode,
    },
  });
  return resp;
};

export const getToken = createAsyncThunk<any, any>(
  "get-token",
  async (data, thunkAPI) => {
    const resp = await axios({
      method: "post",
      url: `${REACT_APP_NATIVE_APP_DISCOVERY_URI}/v1/token`,
      data,
    });
    return resp.data;
  }
);

export const verifyOTP = createAsyncThunk<any, any>(
  "verifyOTP",
  async (data: any, { rejectWithValue }) => {
    console.log('verifyOTP', data)

    try {
      const resp = await axios({
        method: "post",
        url: `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn/factors/${data.tokenData._embedded.factors[0].id
          }/verify${data.apiUrl ? data.apiUrl : ""}`,
        data: {
          stateToken: data.tokenData?.stateToken,
          passCode: data.passCode,
        },
      });
      return resp.data;
    } catch (error: any) {
      if (!error?.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
);

export const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    closeError: (state) => {
      state.isError = false;
    },
    setViewLanguageFilter: (state, action: PayloadAction<boolean>) => {
      state.viewLanguageFilter = action.payload;
    },
    setSmsChallangeToken: (state: any, action) => {
      state.viewLanguageFilter = action.payload;
    },
    setSMSChallangeURL: (state, action: any) => {
      state.viewLanguageFilter = action.payload;
    },


  },
  extraReducers: {
    [exchangeToken.pending.toString()]: (state: AuthSliceState) => {
      state.loading = true;
    },
    [exchangeToken.fulfilled.toString()]: (state, { payload }: any) => {

      state.tokenData = payload;
      state.loading = false;
      state.isError = false;
    },
    [exchangeToken.rejected.toString()]: (state, { payload }: any) => {
      state.tokenData = payload;
      state.loading = false;
      state.isError = true;
    },

    [verifyOTP.pending.toString()]: (state: AuthSliceState) => {
      console.log('verifyOTP.pending', state)

      state.loading = true;
    },
    [verifyOTP.fulfilled.toString()]: (state, { payload }: any) => {
      console.log('verifyOTP.fulfilled', payload)

      state.verifyOTPData = payload;
      state.loading = false;
      state.isAuthenticated = true;
      state.isError = false;
    },
    [verifyOTP.rejected.toString()]: (state, { payload }: any) => {
      console.log('verifyOTP.rejected', payload)

      state.verifyOTPData = payload;
      state.loading = false;
      state.isAuthenticated = false;
    },
    [getToken.fulfilled.toString()]: (state, { payload }: any) => {
      state.accessTokenData = payload;
    },
  },
});

export const { closeError, reset, setViewLanguageFilter } = AuthSlice.actions;

export default AuthSlice.reducer;
