// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties } from "react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { MerchantsAndLocations } from "@brinks/common/api/Api";
import { Text } from "theme-ui";

// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  minWidth: "240px",
  height: "54px",
  marginTop: "4px"
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface Options {
  label: string;
  value: string;
}

interface LocationListMerchantSelectorProps {
  locationsMerchants: MerchantsAndLocations[];
  onChange: (status: null | string) => void;
}

export const LocationListMerchantSelector = ({ onChange, locationsMerchants }: LocationListMerchantSelectorProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<Options[]>([
    { value: 'ALL', label: t("UserLocationFilter.all") }
  ]);

  useEffect(() => {
    handleGenerateOptions(locationsMerchants);
  }, [locationsMerchants]);

  const handleGenerateOptions = (locationsMerchants: MerchantsAndLocations[]) => {

    const optionsToAdd: Options[] = [];

    if (locationsMerchants)
    {
      for (const { id, name } of locationsMerchants) {
        optionsToAdd.push({ value: `${id}`, label: `${name}` });
      }
    }
    setOptions([{ value: 'ALL', label: t("UserLocationFilter.all") }, ...optionsToAdd]);
  }

  const handleChange = (e: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + e.value,
        Page_title: "User management | User overview",
      }
    });

    const option = options.find((option) => option.value === e.value);

    if(option) {
      onChange(option.value === "ALL" ? null : option.value);
    }
  };

  return options.length === 2 && options.find(x => x.value !== "ALL") ? (
    <Text mt={1} data-testid="user-location-merchant-label">
      {options[1].label}
    </Text>
  ) : (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-UserLocationMerchant-select"
      placeholder={`${t("ManageLocations.merchant_placeholder")}: ${options ? "" : options[0]}`}
    />
  );
}
