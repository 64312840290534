/** @jsxImportSource theme-ui */
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import React, { useEffect, useState } from "react";
import { verifyOTP, sendOtp } from "@brinks/common/reducers/authSlice";
import { Flex, Label, Text, Input, Button, Heading, Card } from "theme-ui";
import { useNavigate } from "react-router-dom";
import { ReSendIcon } from "@brinks/common/Icons/Components/resendIcon";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useTranslation } from "react-i18next";
import useTheme from "../../../Hooks/useTheme";
import Logo from "@brinks/common/Icons/Logo";
import LanguageSelector from "../LanguageSelector";
import TagManager from "react-gtm-module";
import { useOktaAuth } from "@okta/okta-react";
import config from "@brinks/common/config";
import PasswordExpired from "../PasswordExpired";
import { PasswordStatus } from "@brinks/common/constants/PasswordStatus";

function OtpPage() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "User registration | Enter verification code",
      },
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [securityCode, setSecurityCode] = useState("");
  const [counter, setCounter] = React.useState(60);
  const { t } = useTranslation();
  const themeType = useTheme();
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);

  // observing user login and token change
  React.useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      console.log("1st OKTA TOKEN STORAGE --> ", authState);
    } else {
      // getting info of user
      oktaAuth
        .getUser()
        .then((info) => {
          // you can pass user info and token from here to server side
        })
        .catch((err) => {
          console.error("error --->", err);
        });
    }
  }, [authState, oktaAuth]);

  const { tokenData, verifyOTPData, isAuthenticated } = useAppSelector(
    (state) => state.authSlice
  );

  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendCode = () => {
    setCounter(60);
    sendOtp(tokenData);
  };
  const getTokensFromSessionToken = (sessionToken: string) => {
    oktaAuth.token
      .getWithoutPrompt({
        responseType: ["token", "id_token", "refresh_token"], // or array of types
        sessionToken: sessionToken, // optional if the user has an existing Okta session
        redirectUri: config.oidc.redirectUri,
      })
      .then((resp) => {
        oktaAuth.tokenManager.setTokens(resp.tokens);
        // setTimeout(() => {
        //   setLoading(false);
        //   window.history.replaceState(null, "null", "/");
        //   navigate("/");
        // }, 2000);
      })
      .catch((error) => console.log("error ----> ", error));
  };

  useEffect(() => {
    if (isAuthenticated) {
      getTokensFromSessionToken(verifyOTPData?.sessionToken);
    }
  }, [verifyOTPData?.sessionToken]);

  useEffect(() => {
    if (verifyOTPData?.errorId) {
      setLoading(false);
      {
        TagManager.dataLayer({
          dataLayer: {
            event: "Click_event",
            Page_title: "User registration | Enter verification code",
            Action: "Error message: code invalid",
          },
        });
      }
    }
  }, [verifyOTPData?.errorId]);
  const [showPasswordExpiredPage, setShowPasswordExpiredPage] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState<PasswordStatus | null>(
    null
  );

  useEffect(() => {
    if (verifyOTPData?.status === PasswordStatus.PASSWORD_EXPIRED) {
      oktaAuth
        .forgotPassword({
          username: verifyOTPData._embedded.user.profile.login,
          factorType: "EMAIL",
        })
        .then(() => {
          setShowPasswordExpiredPage(true);
          setPasswordStatus(PasswordStatus.PASSWORD_EXPIRED);
        })
        .catch((error) => {});
    }
  }, [verifyOTPData?.status]);

  const handleVerifySms = async () => {
    if (!securityCode) {
      return;
    }
    const paylaod: any = {
      tokenData: tokenData,
      passCode: securityCode,
    };
    setLoading(true);
    dispatch(verifyOTP(paylaod));
  };

  return (
    <Flex
      data-testid="OtpPage-right"
      bg={"white"}
      sx={{
        width: ["100%", "60%"],
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Flex
        sx={{ alignSelf: "flex-end" }}
        mr={31}
        mt={30}
        data-testid="OtpPage-lang"
      >
        <LanguageSelector />
      </Flex>
      <Flex sx={{ display: ["block", "none"], mt: 50 }}>
        <Logo themeType={themeType} />
      </Flex>
      {!showPasswordExpiredPage ? (
        <>
          <Card
            data-testid="OtpPage-card"
            sx={{
              width: ["100%", "50%"],
              px: ["16px", 0],
              mt: [50, 200],
            }}
          >
            <Flex
              data-testid="OtpPage-card-wrapper"
              mt={25}
              sx={{
                flexDirection: "column",
              }}
            >
              <Heading data-testid="OtpPage-card-heading">
                {t("Registration.enter_verification")}
              </Heading>
              <Text
                data-testid="OtpPage-card-sms-text"
                mt={25}
                sx={{
                  fontWeight: "weight_400",
                  color: "shade_600",
                }}
              >
                {t("Registration.sms")}
              </Text>
              <Label
                data-testid="OtpPage-card-label"
                mt={25}
                sx={{
                  fontSize: "subText",
                  color: "shade_800",
                }}
              >
                {t("Registration.code_lable")}
              </Label>
              <Input
                data-testid="OtpPage-card-input"
                sx={{
                  p: "17px",
                  border: "1px solid",
                  borderColor: "borderColor",
                  borderRadius: "6px",
                }}
                mt={25}
                type="number"
                autoFocus={true}
                value={securityCode}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "Click_event",
                        Page_title:
                          "User registration | Enter verification code",
                        Action: "Confirm verification code",
                      },
                    });
                    handleVerifySms();
                  }
                }}
                onChange={(e) => {
                  setSecurityCode(e.target.value);
                }}
                placeholder={t("Registration.enter_code")}
                required
              />
              {verifyOTPData?.errorId && (
                <Flex pt={2} data-testid="OtpPage-card-error-flex">
                  <>
                    <ErrorIcon
                      color={"raspberry_500"}
                      data-testid="OtpPage-card-error-icon"
                    />
                    <Text
                      data-testid="OtpPage-card-invalid-code"
                      pl={2}
                      color="raspberry_500"
                      sx={{
                        fontSize: "subText",
                        fontWeight: "weight_400",
                      }}
                    >
                      <>{t("Registration.code_invalid")}</>
                    </Text>
                  </>
                </Flex>
              )}

              <Flex
                data-testid="OtpPage-card-resend-code-flex"
                mt={25}
                sx={{
                  justifyContent: "space-between",
                  alignItems: ["flex-start", "center"],
                  flexDirection: ["column-reverse", "row"],
                }}
              >
                {counter === 0 ? (
                  <Flex
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "Click_event",
                          Page_title:
                            "User registration | Enter verification code",
                          Action: "Resend verification code",
                        },
                      });
                      resendCode();
                    }}
                    sx={{ alignItems: "center", cursor: "pointer" }}
                  >
                    <ReSendIcon color={"primary"} />
                    <Text
                      ml={2}
                      color="royalBlue_500"
                      sx={{
                        fontWeight: "weight_400",
                        lineHeight: "27.2px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {t("Registration.resend_code")}
                    </Text>
                  </Flex>
                ) : (
                  <Text
                    data-testid="OtpPage-card-resend-code-flex-text"
                    color="shade_400"
                  >
                    {t("Registration.resend_code_in")} {counter}
                  </Text>
                )}
                <Button
                  data-testid="OtpPage-card-resend-code-flex-button"
                  onClick={handleVerifySms}
                  variant={`${loading ? "gray" : "primary"}`}
                  sx={{
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                    height: 49,
                    width: ["100%", "169px"],
                    mb: [20, 0],
                    borderRadius: "40px",
                    cursor: "pointer",
                  }}
                >
                  {t("confirm")}
                </Button>
              </Flex>
            </Flex>
          </Card>
        </>
      ) : (
        passwordStatus && <PasswordExpired status={passwordStatus} />
      )}
    </Flex>
  );
}

export default OtpPage;
