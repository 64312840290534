/** @jsxImportSource theme-ui */
import { Text, Flex } from "theme-ui";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../Header/Header";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { resetSealbagState } from "@brinks/common/reducers/registerSealbagSlice";
import { resetState } from "@brinks/common/reducers/orderDetailsSlice";
import { LocationIcon } from "@brinks/common/Icons/Components/location";
import { LogoutIcon } from "@brinks/common/Icons/Components/logout";
import React from "react";

export default function Sidebar({ sideMenus }: { sideMenus: any }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // this is needed during the horizontal migration
  const clearSessionStorage = () => {
    const keys = [
      'locations-storage',
    ]

    keys.forEach(key => {
      if (sessionStorage.getItem(key)) {
        sessionStorage.removeItem(key);
      }
    });
  }

  return (
    <>
      <Flex
        data-testid="flex-sidebar-nav"
        sx={{
          flexDirection: "column",
          height: "100vh",
          alignItems: "center",
          borderRight: "1px solid",
          borderColor: "shade_200",
          px: 24,
          position: "fixed",
          width: "25%",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "none",
        }}
      >
        <Header />
        <nav style={{ width: "100%" }}>
          {sideMenus &&
            sideMenus.map((menu: any, key: any) => (
              <React.Fragment key={key}>
                <NavLink
                  state={{ path: menu.name }}
                  style={({ isActive }) => {
                    return {
                      display: "block",
                      width: "100%",
                      borderRadius: "8px",
                      textDecoration: "none",
                      marginBottom: 20,
                      fontSize: "body",
                      lineHeight: "22.4px",
                      backgroundColor: isActive ? "rgb(112, 166, 245,0.2)" : "",
                    };
                  }}
                  to={`/${menu.name}`}
                  onClick={() => {
                    dispatch(resetSealbagState());
                    dispatch(resetState());
                  }}
                  children={({ isActive }) => {
                    return (
                      <>
                        <Flex
                          pt={16}
                          pb={16}
                          pl={25}
                          sx={{
                            width: "80%",
                            height: "54px",
                            alignItems: "center",
                          }}
                        >
                          <Flex
                            pr={17}
                            sx={{
                              width: 40,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {isActive ? menu.icon_active : menu.icon_standard}
                          </Flex>
                          <Text
                            sx={{
                              color: isActive ? "primary" : "black",
                            }}
                          >
                            {t(menu.label)}
                          </Text>
                        </Flex>
                      </>
                    );
                  }}
                />
                {
                  key === 2 &&
                  <a href="/locations" style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "8px",
                    textDecoration: "none",
                    marginBottom: 20,
                    fontSize: "body",
                    lineHeight: "22.4px"
                  }}>
                    <Flex
                      pt={16}
                      pb={16}
                      pl={25}
                      sx={{
                        width: "80%",
                        height: "54px",
                        alignItems: "center",
                      }}
                    >
                      <Flex
                        pr={17}
                        sx={{
                          width: 40,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LocationIcon color={"icon_standard"} />
                      </Flex>
                      <Text sx={{ color: "red" }}>
                        Locations
                      </Text>
                    </Flex>
                  </a>
                }
              </React.Fragment>
            ))}
            <a href="/test" style={{
              display: "block",
              width: "100%",
              borderRadius: "8px",
              textDecoration: "none",
              marginBottom: 20,
              fontSize: "body",
              lineHeight: "22.4px"
            }}>
              <Flex
                pt={16}
                pb={16}
                pl={25}
                sx={{
                  width: "80%",
                  height: "54px",
                  alignItems: "center",
                }}
              >
                <Flex
                  pr={17}
                  sx={{
                    width: 40,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocationIcon color={"icon_standard"} />
                </Flex>
                <Text sx={{ color: "red" }}>
                  Test page
                </Text>
              </Flex>
            </a>
            <a href="/example" style={{
              display: "block",
              width: "100%",
              borderRadius: "8px",
              textDecoration: "none",
              marginBottom: 20,
              fontSize: "body",
              lineHeight: "22.4px"
            }}>
              <Flex
                pt={16}
                pb={16}
                pl={25}
                sx={{
                  width: "80%",
                  height: "54px",
                  alignItems: "center",
                }}
              >
                <Flex
                  pr={17}
                  sx={{
                    width: 40,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocationIcon color={"icon_standard"} />
                </Flex>
                <Text sx={{ color: "red" }}>
                  Example page
                </Text>
              </Flex>
            </a>
            <NavLink
                state={{ path: 'logout' }}
                style={({ isActive }) => {
                  return {
                    display: "block",
                    width: "100%",
                    borderRadius: "8px",
                    textDecoration: "none",
                    marginBottom: 20,
                    fontSize: "body",
                    lineHeight: "22.4px",
                    backgroundColor: isActive ? "rgb(112, 166, 245,0.2)" : "",
                  };
                }}
                to={`/logout`}
                onClick={() => {
                  clearSessionStorage();

                  dispatch(resetSealbagState());
                  dispatch(resetState());
                }}
                children={({ isActive }) => {
                  return (
                    <>
                      <Flex
                        pt={16}
                        pb={16}
                        pl={25}
                        sx={{
                          width: "80%",
                          height: "54px",
                          alignItems: "center",
                        }}
                      >
                        <Flex
                          pr={17}
                          sx={{
                            width: 40,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {isActive ? <LogoutIcon color={"icon_active"} /> : <LogoutIcon color={"icon_standard"} />}
                        </Flex>
                        <Text
                          sx={{
                            color: isActive ? "primary" : "black",
                          }}
                        >
                          {t("Sidebar.logout")}
                        </Text>
                      </Flex>
                    </>
                  );
                }}
              />
        </nav>
      </Flex>
    </>
  );
}
