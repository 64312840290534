// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties, useMemo } from "react";
import { Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import {
  MerchantWithDependentsAndLocations
} from "@brinks/common/api/Api";
import {
  setSelectedCaseMerchant
} from "@brinks/common/reducers/supportCaseWithMerchantSlice";
import { setCurrentMerchant } from "@brinks/common/reducers/merchantsDetailSlice";
import { setSelectedLocation } from "@brinks/common/reducers/storeLocationSlice";

// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  height: "50px",
  width: "300px",
  paddingLeft: "12px",
  margin: "16px 12px 12px 0",
  boxShadow: "0px 4px 8px rgb(0 0 0 / 10%)",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return { ...provided, ...customControlStyles };
  },
  dropdownIndicator: (provided, state) => ({
    paddingTop: "5px",
    color: "shade_400",
    paddingRight: "20px",
  }),
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface Options {
  label: string;
  value: string;
}

interface SupportCreateMerchantSelectorProps {
  value: null | MerchantWithDependentsAndLocations;
}

export const SupportCreateMerchantSelector = ({
  value,
}: SupportCreateMerchantSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { merchants } = useAppSelector((state) => state.merchantsDetailSlice);

  const [options, setOptions] = useState<Options[]>([]);

  useEffect(() => {
    handleGenerateOptions();
  }, [merchants]);

  const handleGenerateOptions = () => {
    const merchantOptions: Options[] = [];

    if (merchants) {
      for (const { id, name } of merchants) {
        merchantOptions.push({ value: `${id}`, label: `${name}` });
      }
    }

    setOptions([...merchantOptions]);
  };

  const handleChange = (option: Options) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + option.value,
        Page_title: "Orders management | Order change",
      },
    });

    const merchantSelected = merchants
      ? merchants.filter((m) => m.id === option.value)
      : merchants;

    if (option && merchantSelected) {
      dispatch(setCurrentMerchant(merchantSelected[0]));
      dispatch(setSelectedLocation(null));
      dispatch(setSelectedCaseMerchant(merchantSelected));
    }
  };

  const valueSelected = useMemo(() => {
    return options.find((option) => {
      return option.value === value?.id;
    });
  }, [value, options]);

  const hasOneAccount = options.length === 1;

  useEffect(() => {
    if (hasOneAccount) {
      handleChange(options[0]);
    }
  }, [hasOneAccount]);

  return options.length === 1 ? (
    <Text>
      {options[0].label}
    </Text>
  ) : (
    <Select
      options={options}
      value={valueSelected}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-OrderChangeMerchantSelector-select"
      placeholder={t("ManageCase.merchant_placeholder")}
    />
  ) ;
};
