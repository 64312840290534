import {
  REACT_APP_NATIVE_APP_CLIENT_ID,
  REACT_APP_NATIVE_APP_REDIRECT_URI,
  REACT_APP_NATIVE_APP_DISCOVERY_URI,
  REACT_APP_API_KEY,
  REACT_APP_API_URL,
} from "@brinks/common/utils/Config";

export default {
  oidc: {
    clientId: REACT_APP_NATIVE_APP_CLIENT_ID,
    issuer: `${REACT_APP_NATIVE_APP_DISCOVERY_URI}`,
    redirectUri: window.location.origin + "/callback", // REACT_APP_NATIVE_APP_REDIRECT_URI,
    scopes: ["openid", "profile", "email", "offline_access"],
  },
  calendar_api: {
    apiKey: `${REACT_APP_API_KEY}`,
    url: `${REACT_APP_API_URL}`,
  },
};
